<template>
  <div>
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col lg="7" md="12" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">{{ ustMenuAdi }} Menüsü Alt Menü Listesi</h4>
                </b-col>
              </div>
            </b-row>
            <table class="table table-striped mt-1">
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  MENÜ ADI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  MENÜ LİNKİ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  ÜST MENÜ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  SIRA
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in salaryListItems"
                  :key="index"
              >
                <td class="bold pl-1">
                  {{ item.sira }}
                </td>
                <td>
                  {{ item.yazi }}
                </td>
                <td>
                  {{ item.link }}
                </td>
                <td>
                  {{ item.menuTuru == 0 ? 'Standart Menü' : 'Açılır Menü' }}
                </td>
                <td>
                  <b-input-group>
                    <b-form-input
                        :ref="'input' + item.menuID"
                        :value="item.sira"
                        size="sm"
                        @change="siraUpdate(item.menuID)"
                    />
                  </b-input-group>
                </td>
                <td>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="bgDanger"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="altMenuSil(item.menuID)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col lg="5" md="12" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Alt Menü Kaydı</h4>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="bgInfo"
                      class="myButton mr-1 border-0 float-right"
                      to="/menu"
                      :style="{backgroundColor:$store.getters.cardTitleButton}"
                  >
                    <feather-icon
                        icon="ListIcon"
                        class="mr-50"
                    />
                    Üst Menülere Geri Dön
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <b-row class="mt-5">
              <b-col cols="12">
                <b-form-group
                    label="Menü Adı"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="menuAdi"
                      placeholder="Menü Adı"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Alt Menü Türü">
                  <v-select
                      v-model="menuTuru"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="menuTuruOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Modül">
                  <v-select
                      v-model="modul"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="modulOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Hedef">
                  <v-select
                      v-model="hedef"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="hedefOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Site Linkleri">
                  <v-select
                      v-model="siteLink"
                      :components="{ OpenIndicator, Deselect }"
                      label="baslik"
                      :options="siteLinks"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Sıralama"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="siralama"
                      placeholder="Menü Sırası"

                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="bgInfo"
                    class="mt-1 mr-1 border-0 float-right"
                    :style="{backgroundColor:$store.getters.bgSuccess}"
                    @click="altMenuSave"
                >
                  Kaydet
                </b-button>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {
  BProgress, BSpinner,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
} from 'bootstrap-vue'

export default {
  components: {
    BCardActions,
    BProgress,
    BButton,
    BSpinner,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    BFormFile,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      ustMenuID: this.$route.params.id,
      ustMenuAdi: '',
      menuAdi: '',
      siralama: '',
      siteLink: {
        baslik: 'Ana Sayfa',
        link: './'
      },
      siteLinks: [
        {
          baslik: 'Ana Sayfa',
          link: './'
        },
        {
          baslik: 'Açılır Menü',
          link: ''
        },
      ],
      menuTuru: {
        title: 'Standart Menü',
        key: 0
      },
      menuTuruOptions: [
        {
          title: 'Standart Menü',
          key: 0
        },
        {
          title: 'Modül',
          key: 1
        },
      ],
      modul: {
        title: 'Standart Menü',
        key: ''
      },
      modulOptions: [
        {
          title: 'Standart Menü',
          key: ''
        },
        {
          title: 'Birimler',
          key: 1
        },
        {
          title: 'Güncel (Duyuru Kategorileri)',
          key: 2
        },
        {
          title: 'Galeri',
          key: 3
        },
        {
          title: 'Odalar',
          key: 4
        },
      ],
      hedef: {
        title: 'Aynı Pencere',
        key: '_parent'
      },
      hedefOptions: [
        {
          title: 'Aynı Pencere',
          key: '_parent'
        },
        {
          title: 'Yeni Pencere',
          key: '_blank'
        },
      ],
    }
  },
  computed: {
    salaryListItems() {
      return _.orderBy(this.$store.getters.altMenuReturn.liste, 'sira', 'asc')
    },
  },
  created() {
    this.$store
        .dispatch('altMenu', { id: this.$route.params.id })
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          this.ustMenuAdi = res.ustMenu.yazi
          res.linkListesi.forEach(element => {
            this.siteLinks.push({
              baslik: element.baslik,
              link: element.link
            })
          })
        })
        .catch(() => {
          this.spinner = false
        })
  },
  beforeDestroy() {
    this.$store.commit('clearAltMenu')
  },
  methods: {
    altMenuSave() {
      this.$store
          .dispatch('altMenuSave', {
            ustMenuID: this.ustMenuID,
            yazi: this.menuAdi,
            menuTuru: this.menuTuru.key,
            hedef: this.hedef.key,
            link: this.siteLink.link,
            sira: this.siralama,
            modul: this.modul.key,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res);
            (this.siteLink = {
              baslik: 'Ana Sayfa',
              link: './'
            }),
                (this.menuAdi = ''),
                (this.siralama = ''),
                (this.modul = {
                  title: 'Standart Menü',
                  key: ''
                }),
                (this.menuTuru = {
                  title: 'Standart Menü',
                  key: 0
                }),
                (this.hedef = {
                  title: 'Aynı Pencere',
                  key: '_parent'
                }),
                res.linkListesi.forEach(element => {
                  this.siteLinks.push({
                    baslik: element.baslik,
                    link: element.link,
                  })
                })
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    altMenuSil(id) {
      this.$store
          .dispatch('altMenuSil', {
            id: this.ustMenuID,
            sil: id,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    siraUpdate(id) {
      const inputValue = this.$refs[`input${id}`][0].localValue
      this.$store
          .dispatch('altMenuSiraUpdate', {
            ustMenuID: this.ustMenuID,
            id,
            sira: inputValue,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'bgSuccess',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.bold {
  font-weight: 900 !important;
}

[dir] .table th[data-v-de803358],
[dir] .table td[data-v-de803358] {
  padding: 0.4rem;
}

[dir] .table th[data-v-0a6c94d4],
[dir] .table td[data-v-0a6c94d4] {
  max-width: 160px !important;
}

.row {
  justify-content: end !important;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}
</style>
